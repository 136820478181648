import react from "react"
//import {Linking} from 'react-native'
import PCQACL from "../images/pcqacl.png"
import PHA from "../images/pha.png"
import PHILHEALTH from "../images/philhealth.jpg"
import DOH from "../images/doh.png"

export default function Footer(){

  return(
    <div className="footer">
      <div className="footer--info">
        <div className="footer--contact">
          {/* <div className="footer--title">Patient's Portal</div> */}
          <h3 className="footer--title">Contact Us</h3>
          <a href="https://www.facebook.com/QRDiagnostics.Tac" target="_blank" className="footer--text link" rel="noreferrer">
            <i className="fa fa-facebook violet" />
            <span className="underline">
            fb.com/QRDiagnostics.Tac
            </span>

        </a>
          <a href="tel:+639998886694" className="footer--text link">
              <i className="fa fa-phone violet"  />
              0999 888 6694
          </a>
          
        </div>
        <div className="footer--location">
          <h3 className="footer--title">Location</h3>
          <a className="link" href="https://goo.gl/maps/2RsCE3j2qVv513Ws6" target = "_blank" rel="noreferrer">
            <p className="footer--text link">Unit A, M Block Building, Barangay 77, Marasbaras 6500 Tacloban City, Philippines</p>
          </a>
          <div className="footer__section mt-4">
              <h2 className="footer--title">Useful Links</h2>
              <a href="https://patient.qrdiagnosticsph.com" target="_blank" rel="noopener noreferrer">Patient Portal</a>
              <a className="mx-4" href="/privacy-policy">Privacy Policy</a>
              <a href="/terms-of-service">Terms of Service</a>
          </div>
        </div>
        <div className="footer--licensed">
          <h3 className="footer--title">Licensed or accredited by:</h3>
          <div className="footer--pictures">
            <img src={PCQACL} alt="PCQACL Logo"/>
            <img src={PHA} alt="PHA Logo" />
            <img src={PHILHEALTH} alt="PhilHealth Accreditation" />
            <img src={DOH} alt="DOH Logo" />
          </div>

        </div>
      </div>
      <div className="footer--copyright">
        <p>
          ©2022 Quest & Reliance Diagnostics. All Rights Reserved.
        </p>
      </div>
    </div>
  )
}
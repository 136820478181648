import React from "react"
import Location from "../images/location.svg"
import Call from "../images/call.svg"
import Chat from "../images/chat.svg"
import Map from "../images/map.jpg"

export default function ContactUs(){

  return(
    <div className="contactus">
      <h1>Have any questions?</h1>
      <div className="contactus--items">
        <div>
          <a className="link" href="https://www.facebook.com/QRDiagnostics.Tac" target="_blank" >
          <img src={Chat} alt="" />
          <h3 className="contactus--subheading">Message us</h3>
          <h2 className="contactus--heading link">fb.com/QRDiagnostics.Tac</h2>
          </a>

        </div>
        <div>
          <a className="link" href="tel:+639998886694">
          <img src={Call} alt="" />
          <h3 className="contactus--subheading">Call us</h3>
          <h2 className="contactus--heading">0999 888 6694</h2>
          </a>

        </div>
        <div>
          <a className="link" href="https://goo.gl/maps/2RsCE3j2qVv513Ws6" target = "_blank">
          <img src={Location} alt="" />
          <h3 className="contactus--subheading">Visit us</h3>
          <h2 className="contactus--heading">Unit A, M Block Building, Barangay 77, Marasbaras 6500 Tacloban City, Philippines</h2>
          </a>
        </div>
      </div>
      <img src={Map} alt=""/>
    </div>
  )
}
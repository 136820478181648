import React, { 
  // useEffect, 
  useState 
} from "react"

import { Link, useNavigate } from "react-router-dom"

import Logo from "../images/logo-short.png"


export default function NavBar(){
  const [click, setClick] = useState(false);
  const navigate = useNavigate()

  const handleClick = () =>{

    setClick(!click)
  }
  return(
    <nav className="navbar">
      
        <Link to={"/"} className="navbar--left">
          <img src={Logo} alt="" />
          <h3>QUEST & RELIANCE DIAGNOSTICS</h3>
        </Link>
      
      <ul className={click ? "navbar--items" : "navbar--items active"} onClick={handleClick}>
        {/* <Link to="/services/clinical-microscopy" className="navbar--links" >
          <li>Services</li>
        </Link>
        <Link to="/doctors" className="navbar--links">
          <li>Doctors</li>
        </Link>
        <Link to="/" className="navbar--links">
          <li a="https://patient.qrdiagnosticsph.com/">Patient's Portal</li>
        </Link>
        <Link to="/contact-us" className="navbar--links">
          <li>Contact Us</li>
        </Link> */}
        {/* <Link to="/booking" className="navbar--booking">
          <li>Book Now</li>
        </Link> */}
        <li className="navbar--links" onClick={() => navigate("/services/clinical-microscopy")}>Services</li>
        <li className="navbar--links" onClick={() => navigate("/doctors")}>Doctors</li>
        <li className="navbar--links" onClick={() => navigate("/facilities")}>Facilities</li>
        <li className="navbar--links" onClick={() => navigate("/hmo")}>HMO</li>
        <li className="navbar--links" onClick={() => {
          window.open("https://patient.qrdiagnosticsph.com/")
        }}>Patient's Portal</li>
        <li className="navbar--links" onClick={() => navigate("/contact-us")}>Contact Us</li>
        <li className="navbar--links" onClick={() => navigate("/booking")}>Book Now</li>
      </ul>        
      <div className="navbar--icon" onClick={handleClick}>
        <i className={click ? "fa fa-times" : "fa fa-bars"} />
      </div>
    </nav>
 
   
  )
}

    //  <Navbar 
    //     sticky="top"
    //     expand="md"
    //     className="navbar"
    //   >
        
    //     <Navbar.Brand 
    //       href="/"
    //       className="navbar--brand"
    //     >
    //         <img 
    //         src={ Logo } 
    //         className="navbar--logo"
    //         />
    //         <p className="navbar--title">Quest & Reliance Diagnostics</p>
    //     </Navbar.Brand>
        
        

    //     <Navbar.Toggle className="navbar--toggle"/>
    //     <Navbar.Collapse>
    //       <Nav className="navbar--items">
    //         <Nav.Link href="services">Services</Nav.Link>
    //         <Nav.Link href="doctors">Doctors</Nav.Link>
    //         <Nav.Link href="patient.qrdiagnosticsph.com">Patient's Portal</Nav.Link>
    //         <Nav.Link href="contact-us">Contact Us</Nav.Link>
    //         <Button href="booking" variant="primary" className="navbar--button">Book Now</Button>
          
    //       </Nav>
    //     </Navbar.Collapse>
    //   </Navbar>
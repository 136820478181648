// 1 clinical-microscopy
// 2 hematology
// 3 chemistry
// 4 serology
// 5 histopathology
// 6 microbiology
// 7 covid-rapid-tests
// 8 x-ray
// 9 cardiology
export const clinicalMicroscopy = [
  {
    "url":"clinical-microscopy",
    "test_name": "Urinalysis 4 Para",
    "test_info": "",
    "test_price": "70.00"
  }, 
  {
    "url":"clinical-microscopy",
    "test_name": "Urinalysis 10 Para",
    "test_info": "",
    "test_price": "150.00"
  },
  // {
  //   "url":"clinical-microscopy",
  //   "test_name": "Urine Ketone",
  //   "test_info": "",
  //   "test_price": "200.00"
  // },
  // {
  //   "url":"clinical-microscopy",
  //   "test_name": "Urine RBC Morphology",
  //   "test_info": "",
  //   "test_price": "150.00"
  // },
  {
    "url":"clinical-microscopy",
    "test_name": "Fecalysis",
    "test_info": "",
    "test_price": "50.00"
  },
  {
    "url":"clinical-microscopy",
    "test_name": "Fecal Occult Blood",
    "test_info": "",
    "test_price": "350.00"
  },
  {
    "url":"clinical-microscopy",
    "test_name": "Pregnancy test (RPK-Lateral Flow)",
    "test_info": "",
    "test_price": "140.00"
  },
  {
    "url":"clinical-microscopy",
    "test_name": "Serum Pregnancy Test",
    "test_info": "",
    "test_price": "200.00"
  },
  {
    "url":"clinical-microscopy",
    "test_name": "Sperm Analysis",
    "test_info": "",
    "test_price": "370.00"
  }
]
export const hematology = [
  {
    "url":"hematology",
    "test_name": "CBC with Platelet",
    "test_info": "",
    "test_price": "200.00"
  },
  {
    "url":"hematology",
    "test_name": "WBC Differential",
    "test_info": "",
    "test_price": "180.00"
  },
  // {
  //   "url":"hematology",
  //   "test_name": "Hematocrit, PLT",
  //   "test_info": "",
  //   "test_price": "180.00"
  // },
  // {
  //   "url":"hematology",
  //   "test_name": "Hemoglobin, Hematocrit",
  //   "test_info": "",
  //   "test_price": "180.00"
  // },
  {
    "url":"hematology",
    "test_name": "Forward and reverse ABO group (Blood Typing)",
    "test_info": "",
    "test_price": "150.00"
  },
  {
    "url":"hematology",
    "test_name": "Erythrocyte Sedimentation Rate (ESR)",
    "test_info": "",
    "test_price": "170.00"
  },
  {
    "url":"hematology",
    "test_name": "Peripheral Blood Smear / Special Hematology",
    "test_info": "",
    "test_price": "420.00"
  },
  {
    "url":"hematology",
    "test_name": "Clotting & Bleeding Time",
    "test_info": "",
    "test_price": "180.00"
  },
  {
    "url":"hematology",
    "test_name": "Reticulocyte Count/Retics",
    "test_info": "",
    "test_price": "300.00"
  }
]
export const histopathology = [
// {
//     "url":"histopathology",
//     "test_name": "Reticulocyte Count/Retics",
//     "test_info": "",
//     "test_price": "300.00"
//   },
  {
    "url":"histopathology",
    "test_name": "Paps Smear",
    "test_info": "",
    "test_price": "800.00"
  }
]
export const microbiology = [
  {
    "url":"microbiology",
    "test_name": "Gram Stain",
    "test_info": "",
    "test_price": "350.00"
  }, 
  {
    "url":"microbiology",
    "test_name": "KOH",
    "test_info": "",
    "test_price": "300.00"
  }
]
export const others = [
  {
    "url":"others",
    "test_name": "Antigen Rapid Swab (Nasal)",
    "test_info": "",
    "test_price": "660.00"
  },
  {
    "url":"others",
    "test_name": "Drug Test",
    "test_info": "",
    "test_price": "300.00"
  }
]

export const xRay = [
  {
    "url":"x-ray",
    "test_name": "Abdominal Supine/Upright",
    "test_info": "",
    "test_price": "800.00"
  },
  {
    "url":"x-ray",
    "test_name": "Ankle APL",
    "test_info": "",
    "test_price": "550.00"
  },
  {
    "url":"x-ray",
    "test_name": "Apicolordotic",
    "test_info": "",
    "test_price": "240.00"
  },
  {
    "url":"x-ray",
    "test_name": "Chest PA",
    "test_info": "",
    "test_price": "200.00"
  },
  // {
  //   "url":"x-ray",
  //   "test_name": "Chest PA (Mobile)",
  //   "test_info": "",
  //   "test_price": "250.00"
  // },
  {
    "url":"x-ray",
    "test_name": "Chest PAL/APL",
    "test_info": "",
    "test_price": "380.00"
  },
  {
    "url":"x-ray",
    "test_name": "Chest Bucky",
    "test_info": "",
    "test_price": "370.00"
  },
  {
    "url":"x-ray",
    "test_name": "Cervical APL",
    "test_info": "",
    "test_price": "390.00"
  },
  {
    "url":"x-ray",
    "test_name": "Elbow",
    "test_info": "",
    "test_price": "530.00"
  },
  {
    "url":"x-ray",
    "test_name": "Femur APL",
    "test_info": "",
    "test_price": "650.00"
  },
  {
    "url":"x-ray",
    "test_name": "Flat Plate Upright",
    "test_info": "",
    "test_price": "500.00"
  },
  {
    "url":"x-ray",
    "test_name": "Forearm APL",
    "test_info": "",
    "test_price": "640.00"
  },
  {
    "url":"x-ray",
    "test_name": "Foot APOL",
    "test_info": "",
    "test_price": "580.00"
  },
  {
    "url":"x-ray",
    "test_name": "Hand APOL",
    "test_info": "",
    "test_price": "580.00"
  },
  {
    "url":"x-ray",
    "test_name": "Humerus  APL",
    "test_info": "",
    "test_price": "420.00"
  },
  {
    "url":"x-ray",
    "test_name": "KUB",
    "test_info": "",
    "test_price": "210.00"
  },
  {
    "url":"x-ray",
    "test_name": "Knee APL",
    "test_info": "",
    "test_price": "580.00"
  },
  {
    "url":"x-ray",
    "test_name": "Lumbo-Sacral APL",
    "test_info": "",
    "test_price": "900.00"
  },
  {
    "url":"x-ray",
    "test_name": "Leg APL",
    "test_info": "",
    "test_price": "650.00"
  },
  {
    "url":"x-ray",
    "test_name": "Mastoid Series",
    "test_info": "",
    "test_price": "1,300.00"
  },
  {
    "url":"x-ray",
    "test_name": "Mandible APO (One-side)",
    "test_info": "",
    "test_price": "450.00"
  },
  {
    "url":"x-ray",
    "test_name": "Paranasal Sinuses Complete",
    "test_info": "",
    "test_price": "480.00"
  },
  {
    "url":"x-ray",
    "test_name": "Pelvis AP",
    "test_info": "",
    "test_price": "580.00"
  },
  {
    "url":"x-ray",
    "test_name": "Scoliotic Series",
    "test_info": "",
    "test_price": "1,320.00"
  },
  {
    "url":"x-ray",
    "test_name": "Shoulder AP",
    "test_info": "",
    "test_price": "380.00"
  },
  {
    "url":"x-ray",
    "test_name": "Skull APL",
    "test_info": "",
    "test_price": "550.00"
  },
  {
    "url":"x-ray",
    "test_name": "Thoraco-Lumbar APL",
    "test_info": "",
    "test_price": "1,380.00"
  },
  {
    "url":"x-ray",
    "test_name": "T-Cage",
    "test_info": "",
    "test_price": "400.00"
  },
  {
    "url":"x-ray",
    "test_name": "Wrist APOL",
    "test_info": "",
    "test_price": "540.00"
  },
  {
    "url":"x-ray",
    "test_name": "Additional Xray Service",
    "test_info": "",
    "test_price": "250.00"
  }
]
export const cardiology = [
  {
    "url":"cardiology",
    "test_name": "Doppler 2- Echo",
    "test_info": "",
    "test_price": "3,200.00"
  }, 
 {
    "url":"cardiology",
    "test_name": "ECG",
    "test_info": "",
    "test_price": "200.00"
  }
]

export const chemElectrolytes = [
  {
    "url":"chemistry",
    "test_name": "Electrolytes (NaKCl)",
    "test_info": " Sodium (Na), Potassium (K), Chloride (Cl)",
    "test_price": "980.00"
  }, 
  // {
  //   "url":"chemistry",
  //   "test_name": "Ionized Calcium (Ca)",
  //   "test_info": "",
  //   "test_price": "450.00"
  // },
  {
    "url":"chemistry",
    "test_name": "Ionized Calcium (Ca)",
    "test_info": "",
    "test_price": "450.00"
  }, 
  {
    "url":"chemistry",
    "test_name": "Magnesium",
    "test_info": "",
    "test_price": "625.00"
  }, 
  {
    "url":"chemistry",
    "test_name": "Phosphorus",
    "test_info": "",
    "test_price": "600.00"
  }
]

export const chemGlucoseTests = [
  {
    "url":"chemistry",
    "test_name": "Fasting Blood Sugar",
    "test_info": "",
    "test_price": "125.00"
  }, 
  {
    "url":"chemistry",
    "test_name": "Random Blood Sugar",
    "test_info": "",
    "test_price": "125.00"
  }, 
  // {
  //   "url":"chemistry",
  //   "test_name": "OGTT 2 Extractions",
  //   "test_info": " Fasting, 1st hour (75g) ",
  //   "test_price": "450.00"
  // }, 
  {
    "url":"chemistry",
    "test_name": "OGTT 3 Extractions (75g)",
    "test_info": "",
    "test_price": "760.00"
  }, 
  {
    "url":"chemistry",
    "test_name": "2 hrs PPBS",
    "test_info": "",
    "test_price": "200.00"
  }, 
  {
    "url":"chemistry",
    "test_name": "HbA1c",
    "test_info": "",
    "test_price": "880.00"
  }
]

export const chemKidneyFunctionTests = [
  {
    "url":"chemistry",
    "test_name": "Creatinine",
    "test_info": "",
    "test_price": "140.00"
  },
  {
    "url":"chemistry",
    "test_name": "Blood Urea Nitrogen",
    "test_info": "",
    "test_price": "125.00"
  },
  {
    "url":"chemistry",
    "test_name": "Blood Uric Acid",
    "test_info": "",
    "test_price": "125.00"
  },
  {
    "url":"chemistry",
    "test_name": "TPAG",
    "test_info": " Total Protein, Albumin, Globulin",
    "test_price": "350.00"
  },
  {
    "url":"chemistry",
    "test_name": "Total Protein only",
    "test_info": "",
    "test_price": "200.00"
  },
  {
    "url":"chemistry",
    "test_name": "Albumin only",
    "test_info": "",
    "test_price": "180.00"
  }
]

export const chemLipidProfile = [
  {
    "url":"chemistry",
    "test_name": "Cholesterol, Triglycerides, HDL, LDL, VLDL",
    "test_info": "",
    "test_price": "600.00"
  },
  {
    "url":"chemistry",
    "test_name": "Total Cholesterol only",
    "test_info": "",
    "test_price": "190.00"
  },
  {
    "url":"chemistry",
    "test_name": "HDL only",
    "test_info": "",
    "test_price": "280.00"
  },
  {
    "url":"chemistry",
    "test_name": "Triglycerides only",
    "test_info": "",
    "test_price": "200.00"
  }
]

export const chemPancreaticTest = [
  {
    "url":"chemistry",
    "test_name": "Amylase",
    "test_info": "",
    "test_price": "350.00"
  } 
] 

export const chemLiverFunctionTests = [
  {
    "url":"chemistry",
    "test_name": "ALT/SGPT",
    "test_info": "",
    "test_price": "180.00"
  }, 
  {
    "url":"chemistry",
    "test_name": "AST/SGOT",
    "test_info": "",
    "test_price": "180.00"
  }, 
  {
    "url":"chemistry",
    "test_name": "Alkaline Phosphatase (ALP)",
    "test_info": "",
    "test_price": "450.00"
  }, 
  {
    "url":"chemistry",
    "test_name": "Bilirubin Panel",
    "test_info": "",
    "test_price": "300.00"
  }, 
  {
    "url":"chemistry",
    "test_name": "Direct Bilirubin",
    "test_info": "",
    "test_price": "140.00"
  }, 
  {
    "url":"chemistry",
    "test_name": "Indirect Bilirubin",
    "test_info": "",
    "test_price": "140.00"
  }, 
  {
    "url":"chemistry",
    "test_name": "Lactate Dehydrogenase (LDH)",
    "test_info": "",
    "test_price": "580.00"
  }, 
  {
    "url":"chemistry",
    "test_name": "Gamma-glutamyl Transferase (GGT)",
    "test_info": "",
    "test_price": "300.00"
  }, 

]

export const seroImmunology = [
  {
    "url":"serology",
    "test_name": "Dengue",
    "test_info": "",
    "test_price": "1,300.00"
  },
  {
    "url":"serology",
    "test_name": "Anti/Syphilis/RPR/VDRL",
    "test_info": "",
    "test_price": "250.00"
  },
  {
    "url":"serology",
    "test_name": "HIV Screening (Anti HIV)",
    "test_info": "",
    "test_price": "600.00"
  },
  {
    "url":"serology",
    "test_name": "H. Pylori",
    "test_info": "",
    "test_price": "800.00"
  }
]
export const seroHepatitis = [
  {
    "url":"serology",
    "test_name": "HBSag",
    "test_info": "Hepatitis B Antigen",
    "test_price": "290.00"
  },
  {
    "url":"serology",
    "test_name": "Anti HBs/HBSab",
    "test_info": "Hepatitis B Antibody",
    "test_price": "500.00"
  },
  {
    "url":"serology",
    "test_name": "Anti HCV",
    "test_info": "",
    "test_price": "530.00"
  },
  {
    "url":"serology",
    "test_name": "Anti HAV",
    "test_info": "",
    "test_price": "750.00"
  }
]

export const seroThyroid = [
  {
    "url":"serology",
    "test_name": "TSH",
    "test_info": "",
    "test_price": "900.00"
  },
  {
    "url":"serology",
    "test_name": "FT4",
    "test_info": "",
    "test_price": "750.00"
  },
  {
    "url":"serology",
    "test_name": "FT3",
    "test_info": "",
    "test_price": "750.00"
  },
  {
    "url":"serology",
    "test_name": "T4",
    "test_info": "",
    "test_price": "750.00"
  },
  {
    "url":"serology",
    "test_name": "T3",
    "test_info": "",
    "test_price": "750.00"
  }
]

export const seroCoaguation = [
  {
    "url":"serology",
    "test_name": "APTT",
    "test_info": "",
    "test_price": "550.00"
  },
  {
    "url":"serology",
    "test_name": "Prothrombin",
    "test_info": "",
    "test_price": "550.00"
  },
]
export const seroTumor= [
  {
    "url":"serology",
    "test_name": "PSA",
    "test_info": "",
    "test_price": "1,300.00"
  },
  // {
  //   "url":"serology",
  //   "test_name": "CA - 125",
  //   "test_info": "Ovarian",
  //   "test_price": "1,500.00"
  // }
  {
    "url":"serology",
    "test_name": "CEA",
    "test_info": "Ovarian",
    "test_price": "1,100.00"
  }
]

export const ultrasound = [
  {
    "url":"ultrasound",
    "test_name": "Breast",
    "test_info": "Radiologist (Appointment Required)",
    "test_price": "1,250.00"
  },
  {
    "url":"ultrasound",
    "test_name": "Cranial",
    "test_info": " Radiologist (Appointment Required)",
    "test_price": "800.00"
  },
  {
    "url":"ultrasound",
    "test_name": "Hepatobiliary tree",
    "test_info": "",
    "test_price": "800.00"
  },
  {
    "url":"ultrasound",
    "test_name": "Inguinal Area",
    "test_info": "Radiologist (Appointment Required)",
    "test_price": "700.00"
  },
  {
    "url":"ultrasound",
    "test_name": "KUB Renal",
    "test_info": "",
    "test_price": "950.00"
  },
  {
    "url":"ultrasound",
    "test_name": "KUB Prostate",
    "test_info": "",
    "test_price": "1,350.00"
  },
  {
    "url":"ultrasound",
    "test_name": "Lower Abdominal",
    "test_info": "",
    "test_price": "1,300.00"
  },
  {
    "url":"ultrasound",
    "test_name": "Neck",
    "test_info": "",
    "test_price": "1,000.00"
  },
  {
    "url":"ultrasound",
    "test_name": "Prostate Gland",
    "test_info": "",
    "test_price": "600.00"
  },
  {
    "url":"ultrasound",
    "test_name": "Scrotal",
    "test_info": "Radiologist (Appointment Required)",
    "test_price": "1,200.00"
  },
  {
    "url":"ultrasound",
    "test_name": "Small Parts Utz",
    "test_info": "Radiologist (Appointment Required)",
    "test_price": "650.00"
  },
  {
    "url":"ultrasound",
    "test_name": "Thyroid",
    "test_info": "",
    "test_price": "750.00"
  },
  {
    "url":"ultrasound",
    "test_name": "Whole Abdominal",
    "test_info": "",
    "test_price": "1,750.00"
  },
  {
    "url":"ultrasound",
    "test_name": "Whole Abdominal w/ Prostate",
    "test_info": "",
    "test_price": "2,200.00"
  },
  {
    "url":"ultrasound",
    "test_name": "Whole Abdominal w/ Pelvic",
    "test_info": "",
    "test_price": "2,200.00"
  },
  // {
  //   "url":"ultrasound",
  //   "test_name": "Congenital Screening",
  //   "test_info": "",
  //   "test_price": ""
  // },
  // {
  //   "url":"ultrasound",
  //   "test_name": "Pelvic (Fetal Aging)",
  //   "test_info": "Radiologist (Appointment Required)",
  //   "test_price": "875.00"
  // },
  // {
  //   "url":"ultrasound",
  //   "test_name": "Transvaginal (TVS)",
  //   "test_info": "Radiologist (Appointment Required)",
  //   "test_price": "800.00"
  // },
  // {
  //   "url":"ultrasound",
  //   "test_name": "Upper Abdominal",
  //   "test_info": "",
  //   "test_price": "900.00"
  // }
]

export const obgyneUltrasound = [
  {
    "url":"ultrasound",
    "test_name": "TAS, BPS/Biophysical Profile",
    "test_price": "800.00"
  },
  {
    "url":"ultrasound",
    "test_name": "Transvaginal (1st Trimester)",
    "test_price": "900.00"
  },
  
  {
    "url":"ultrasound",
    "test_name": "Transvaginal (Gyne)",
    "test_price": "1,000.00"
  },
  {
    "url":"ultrasound",
    "test_name": "Transvaginal (Gyne) with doppler",
    "test_price": "1,500.00"
  },
  {
    "url":"ultrasound",
    "test_name": "TAS/BPS with servical length",
    "test_price": "1,000.00"
  },
  {
    "url":"ultrasound",
    "test_name": "OB Doppler",
    "test_price": "2,500.00"
  },
  {
    "url":"ultrasound",
    "test_name": "Placenta Doppler",
    "test_price": "2,500.00"
  }
]

export const vaccines = [
  {
    "url":"vaccines",
    "test_name": "Hepatitis B",
    // "test_price": "1,600.00"
    "test_price": "2,000.00"
  },
  {
    "url":"vaccines",
    "test_name": "Pneumococcal 23",
    // "test_price": "3,100.00"
    "test_price": "3,500.00"
  },
  {
    "url":"vaccines",
    "test_name": "Flu", 
    // "test_price": "1,400.00"
    "test_price": "1,00.00"
  },
  {
    "url":"vaccines",
    "test_name": "Consultation",
    "test_price": "500.00"
  }
]

export const diagnostic = [
  {
    "url":"diagnostic",
    "test_name": "LABORATORY SERVICES"
  },
  {
    "url":"diagnostic",
    "test_name": "DRUG TESTING"
  },
  {
    "url":"diagnostic",
    "test_name": "XRAY SERVICES"
  },
  {
    "url":"diagnostic",
    "test_name": "ECG"
  },
  {
    "url":"diagnostic",
    "test_name": "2D Echo"
  },
  {
    "url":"diagnostic",
    "test_name": "Ultrasound"
  },
  {
    "url":"diagnostic",
    "test_name": "Covid Antigen Swab"
  },
  {
    "url":"diagnostic",
    "test_name": "MD Consultations"
  },
  {
    "url":"diagnostic",
    "test_name": "Medical Certificates"
  },
  {
    "url":"diagnostic",
    "test_name": "Company Annual Exams"
  },
  {
    "url":"diagnostic",
    "test_name": "Mobile Clinic Services"
  },
]

export const homeWithin2kms = [
  {
    "url":"home-service",
    "test_name": "1-2 Pax",
    "test_info": "",
    "test_price": "250.00/person"
  },
  {
    "url":"home-service",
    "test_name": "3 or more",
    "test_info": "",
    "test_price": "150.00/person"
  }
]

export const homeOutside2kms = [
   {
    "url":"home-service",
    "test_name": "1-2 Pax",
    "test_info": "",
    "test_price": "300.00/person"
  },
  {
    "url":"home-service",
    "test_name": "3 or more",
    "test_info": "",
    "test_price": "180.00/person"
  } 
]

export const homeOutside = [
  {
    "url":"home-service",
    "test_name": "Per KM",
    "test_info": "",
    "test_price": "150.00/person"
  }   
]

//Employment A
export const packagesEmploymentA = [
  {
    "url":"packages",
    "test_name": "CBC, Urinalysis, Chest PA (X-Ray)",
    "test_info": "",
    "test_price": "490.00"
  },
  {
    "url":"packages",
    "test_name": "For group bookings of 15 pax or more",
    "test_info": "",
    "test_price": "350.00"
  }
]

//Employment B
export const packagesEmploymentB = [
  {
    "url":"packages",
    "test_name": "CBC, Urinalysis, Stool Exam, Chest PA (X-Ray), Physical Exam, Medical Certificate",
    "test_info": "",
    "test_price": "890.00"
  },
  {
    "url":"packages",
    "test_name": "For group bookings of 15 pax or more",
    "test_info": "",
    "test_price": "820.00"
  }
]

//Standard Package
export const packagesEmploymentC = [
  {
    "url":"packages",
    "test_name": "CBC, Urinalysis, Chest XRAY PA, Drug Test, HEP B Screening",
    "test_info": "",
    "test_price": "960.00" 
    //old price 1,750.00"
  },
  {
    "url":"packages",
    "test_name": "For group bookings of 15 pax or more",
    "test_info": "",
    "test_price": "910.00"
    //old price 1,500.00"
  }
]

//Uniformed Personnel
export const packagesEmploymentD = [
  {
    "url":"packages",
    "test_name": "Option 1: CBC, Creatinine, Urinalysis, Chest XRAY PA, Fasting Blood Sugar, ECG, Hep B Screening",
    "test_info": "",
    "test_price": "1,215.00"
  },
  {
    "url":"packages",
    "test_name": "Option 2: CBC, ECG, Urinalysis ",
    "test_info": "",
    "test_price": "400.00"
  },
  {
    "url":"packages",
    "test_name": "Option 3: CBC, U/A, Chest Xray, Creatinine, FBS, ECG, HEP B Screening, BUA, Total Cholesterol, SGPT, SGOT",
    "test_info": "",
    "test_price": "1,790.00"
  }
]

//Kasambahay/Driver
export const packagesEmploymentE = [
  {
    "url":"packages",
    "test_name": "Option 1: Urinalysis, Chest XRAY PA, COVID Antigen Swab, Hep B Screening",
    "test_info": "",
    "test_price": "1,180.00"
  },
  {
    "url":"packages",
    "test_name": "Option 2: Urinalysis, Chest XRAY PA, Covid Antigen Swab, Hep B Screening, Drug Test",
    "test_info": "",
    "test_price": "1,470.00"
  }
]

//Comprehensive
export const packagesEmploymentF= [
  {
    "url":"packages",
    "test_name": "CBC, ECG, Lipid Panel, Creatinine, Uric Acid, SGPT, Fasting Blood Sugar, Urinalysis, Chest XRAY PA, Hep B Screening",
    "test_info": "",
    "test_price": "1,850.00"
  },
  {
    "url":"packages",
    "test_name": "For group bookings of 15 pax or more",
    "test_info": "",
    "test_price": "1,650.00"
  }
]

//Annual Wellness Package
//Basic Package
export const packagesAnnualA = [
  {
    "url":"packages",
    "test_name": "Fasting Blood Sugar, Blood Uric Nitrogen, Creatinine, Uric Acid, Lipid Panel, SGPT/ALT",
    "test_info": "",
    "test_price": "1,000.00"
  }
]

//Standard Package
export const packagesAnnualB = [
  {
    "url":"packages",
    "test_name": "Fasting Blood Sugar, Blood Uric Nitrogen, Creatinine, Uric Acid, Lipid Panel, SGPT/ALT, SGOT/AST, CBC with Platelet, Urinalysis",
    "test_info": "",
    "test_price": "1,550.00"
  }
]

//Premium Package
export const packagesAnnualC = [
  {
    "url":"packages",
    "test_name": "Fasting Blood Sugar, Blood Uric Nitrogen, Creatinine, Uric Acid, Lipid Panel, SGPT/ALT, SGOT/AST, Akaline Phosphate, Electrolytes, CBC, Urinalysis",
    "test_info": "",
    "test_price": "2,500.00"
  }
]

//Gold Package
export const packagesAnnualD = [
  {
    "url":"packages",
    "test_name": "Fasting Blood Sugar, Blood Uric Nitrogen, Creatinine, Uric Acid, Lipid Panel, SGPT/ALT, SGOT/AST, Akaline Phosphatase, Electrolytes, CBC, Urinalysis, 12-Lead ECG, HBA1C, Free Breakfast Voucher",
    "test_info": "",
    "test_price": "3,900.00"
  }
]

//Special Package Diabetic Panel
export const packagesAnnualE = [
  {
    "url":"packages",
    "test_name": "Fasting Blood Sugar, Blood Uric Nitrogen, Creatinine, Uric Acid, Lipid Panel, SGPT/ALT, SGOT/AST, Electrolytes, CBC, Urinalysis, HBA1C",
    "test_info": "",
    "test_price": "3,300.00"
  }
]
export const medicalCerts = [
  {
    "url":"medical-certificate",
    "test_name": "Medical Certificate",
    "test_info": "",
    "test_price": "200.00"
  },
  {
    "url":"medical-certificate",
    "test_name": "Physical Exam",
    "test_info": "",
    "test_price": "500.00"
  },
  {
    "url":"medical-certificate",
    "test_name": "Consultation",
    "test_info": "",
    "test_price": "500.00"
  }  
]
import React from "react"
import aboutPic from "../images/qr_microscope.jpg"

export default function AboutUs(){
  return(
    <div className="aboutus">
      <div className="aboutus--top">
        <h1>About</h1>
        <h1><span className="violet">Quest  & Reliance Diagnostics</span></h1>
        <p>
          We are the preferred diagnostic healthcare partner, delivering unparalleled customer service. Providing <span className="violet">reliable</span> results, one <span className="violet">test</span> at a time.
        </p>
      </div>
      <div className="aboutus--bottom">
        <div className="aboutus--first">
          <h3>Our <span className="violet">Mission</span></h3>
          <p>To provide innovative, accurate and high-quality diagnostic care.
          </p>
        </div>
        <div className="aboutus--second">
          <img className="aboutus--img" src={aboutPic} alt="" />
        </div>
        <div className="aboutus--third">
          <h3>Our <span className="violet">Vision</span></h3>
          <p>To become the preferred diagnostic healthcare partner delivering unparalleled customer service.</p>
        </div>
        

      </div>
    </div>
    
  )
}
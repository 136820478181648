export default [
  {
    "id": 1,
    "url":"clinical-microscopy",
    "service_name": "Clinical Microscopy"
    
  },
  {
    "id": 2,
    "url":"hematology",
    "service_name": "Hematology"
  },
  {
    "id": 3,
    "url":"chemistry",
    "service_name": "Chemistry"
  },
  {
    "id": 4,
    "url":"serology",
    "service_name": "Serology"
  },
  {
    "id": 5,
    "url":"histopathology",
    "service_name": "Histopathology"
  },
  {
    "id": 6,
    "url":"microbiology",
    "service_name": "Microbiology"
  },
  {
    "id": 7,
    "url":"others",
    "service_name": "Others"
  },
  {
    "id": 8,
    "url":"x-ray",
    "service_name": "X-Ray"
  },
  {
    "id": 9,
    "url":"cardiology",
    "service_name": "Cardiology"
  },
  // {
  //   "id": 10,
  //   "url":"home-service",
  //   "service_name": "Home Service"
  // },
  {
    "id": 11,
    "url":"packages",
    "service_name": "Packages"
  },
    {
    "id": 12,
    "url":"medical-certificate",
    "service_name": "Medical Certificate"
  },
  {
    "id": 13,
    "url":"ultrasound",
    "service_name": "Ultrasound"
  },
  {
    "id": 14,
    "url":"vaccines",
    "service_name": "Vaccines"
  },
  {
    "id": 15,
    "url":"diagnostic",
    "service_name": "Diagnostic Services"
  }
]


import React, {useEffect} from "react"
import 'bootstrap/dist/css/bootstrap.css'

import { Route, Routes, BrowserRouter  } from "react-router-dom"
import './index.css';
import './App.css';

import NavBar from './components/NavBar';
// import AboutUs from "./pages/AboutUs";
import BookNow from "./pages/BookNow";
import Facilities from "./pages/Facilities.js";
import Hmo from './pages/HmoPage.js';
import ContactUs from "./pages/ContactUs";
import Doctors from "./pages/Doctors";
import Home from "./pages/Home";
import PatientPortal from "./pages/PatientPortal";
import Services from "./pages/Services";
import Footer from "./components/Footer.js"
import NotFound from "./pages/NotFound";
import GoToTop from "./components/GoToTop";
import Results from "./pages/Results";

export default function App() {
  useEffect(() => {
  window.scrollTo(0, 0)
}, [])
  return (
    <div className="app">
      <BrowserRouter>
        <NavBar/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services"  element={<Services />}>
            <Route path=":id" element={<Services />} />
          </Route>

          <Route path="/doctors" element={<Doctors />} />

          <Route path="/patient-portal" element={<PatientPortal />} />
          
          <Route path="/results" element={<Results />} />

          <Route path="/contact-us" element={<ContactUs />} />

          <Route path="/booking" element={<BookNow />} />

          <Route path="/facilities" element={<Facilities />} />

          <Route path="/hmo" element={<Hmo />} />

          <Route path="*" element={<NotFound />} />

        </Routes>
        <GoToTop />
        <Footer />
      </BrowserRouter>
    </div>
      
    

    

  );
}

//  <Route exact path="/" component={Home} />
    //     <Route exact path="/about-us" component={AboutUs} />
    //     <Route exact path="/services" component={Services} />
    //     <Route exact path="/doctors" component={Doctors} />
    //     <Route exact path="patient.qrdiagnosticsph.com/" component={PatientPortal} />
    //     <Route exact path="/contact-us" component={ContactUs} />
    //     <Route exact path="/booking" component={BookNow} />
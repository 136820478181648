import React from "react"
import ServicesList from "../ServicesList"
import { Link } from "react-router-dom"
import Image from "../images/logo-short.png"
import SoonList from "../SoonList"
export default function HomeServices(){



  const servicesElements=ServicesList.map(
    services=>
    <Link 
      to={`/services/${services.url} `}
      className="services--links"
    >
      <button className="services--items">
        {services.service_name}
        <i className="fa fa-arrow-right" />
      </button>   
    </Link>
    )

    const soonElements=SoonList.map(
    services=>
    <Link 
      to={`/services/${services.url} `}
      className="services--links"
    >
      <button className="services--items yellow yellowcontainer">
        {services.service_name}
        <i className="fa fa-arrow-right yellow" />
      </button>   
    </Link>
    )


  return(
    <div className="services">
      <div className="services--left">
        <img src={Image} alt="" />
        <h1 className="services--title">
          Learn more about our <span className="violet">services</span>
        </h1>
      </div>

      <div className="services--right">
        {servicesElements}
        {/* <h3 className="services--available">
          Available soon:
        </h3>
        {soonElements} */}
      </div>

    </div>
  )
}


      //   (services.id===1)?"blood-chemistry":
      //   (services.id===2)?"clinical-laboratory":
      //   (services.id===3)?"clinical-microscopy":
      //   (services.id===4)?"covid-antigen-swab-test":
      //   (services.id===5)?"ecg":
      //   (services.id===6)?"hematology":
      //   (services.id===7)?"koh-gram-stain":
      //   (services.id===8)?"serology":
      //   (services.id===9)?"x-ray" : ""
      // }`
import React from "react"
import BookNowBtn from "./BookNowBtn"
import Logo from "../images/logo-long.png"
import Image from "../images/hero_bg.png"

export default function Hero(){
  return(
    <div className="hero">

      <div className="hero--left">
        <img src={Logo} alt="" className="hero--logo" />
        {/* <BookNowBtn /> */}
      </div>
      <div className="hero--right">
        
      </div>
      
      
      
        


      
      
    </div>
  )
}
         
      
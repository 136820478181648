import React from 'react'
import { Carousel, Image } from 'antd';
import img1 from '../images/carousel_one.png'
import img2 from '../images/carousel_two.png'
import img3 from '../images/carousel_three.png'
import img4 from '../images/carousel_four.png'

const Facilities = () => {

    return (
        <div className='p-5'>
            <Carousel autoplay arrows autoplaySpeed={2000} className='carousel-container p-4'>
                <div className='center'>
                    <Image
                        width={600}
                        height='auto'
                        src={img1}
                        alt="img1"
                        preview={false}
                    />
                </div>
                <div className='center'>
                    <Image
                        width={600}
                        height='auto'
                        src={img2}
                        alt="img2"
                        preview={false}
                    />
                </div>
                <div className='center'>
                    <Image
                        width={600}
                        height='auto'
                        src={img3}
                        alt="img3"
                        preview={false}
                    />
                </div>
                <div className='center'>
                    <Image
                        width={600}
                        height='auto'
                        src={img4}
                        alt="img4"
                        preview={false}
                    />
                </div>
            </Carousel>
        </div>
    )
}

export default Facilities

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "../src/avenir/avenir_ff/AvenirLTStd-Black.otf"
import "../src/avenir/avenir_ff/AvenirLTStd-Book.otf"
import "../src/avenir/avenir_ff/AvenirLTStd-Roman.otf"
import App from './App';
import reportWebVitals from './reportWebVitals';

//GLOBAL VARIABLES
window.$link= "https://blue.qrdiagnosticsph.com/";
window.$api_key = "Y5QubbhTOb";

ReactDOM.render(
 <React.StrictMode>
   <App />
 </React.StrictMode>, 
  document.getElementById('root')
);


reportWebVitals();

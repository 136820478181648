import React, {useState} from "react"
import { useLocation } from "react-router-dom"



export default function Results(){
  const {state} = useLocation()
  const type = state.data.booking_details[0].type
  const bookID = state.data.booking_details[0].id

  console.log(state)
  return(
    <>
    {type==="lab"?
    <div className="results">
      <div className="results--patientcode">
        <h3 className="results--label">
          Booking No.:
        </h3>
        <h2 className="results--code">
          {state.data.booking[0].id}  
        </h2>
      </div>
      <div className="results--details">
        <h3 className="results--label">
          Patient Name:
        </h3>
        <h1 className="results--date">
          {/* Mmmm DD, YYYY, <br />
          HH:mm */}
          {state.data.customer.first_name + " " + state.data.customer.last_name}
        </h1>
      </div>
      <div className="results--details">
        <h3 className="results--label">
          Registered on:
        </h3>
        <h1 className="results--date">
          {/* Mmmm DD, YYYY, <br />
          HH:mm */}
          {state.data.booking[0].booking_time}
        </h1>
      </div>

      <div className="results--details">
        <h3 className="results--label bold">
          Releasing of Results:
        </h3>
        <h1 className="results--date darkerblue">
          {/* Mmmm DD, YYYY, <br />
          HH:mm           */}
          {state.data.booking_details[0].updated_on === null?"Not yet available":state.data.booking_details[0].updated_on}  
        </h1>
      </div>
      <a href="/patient-portal"  >
                Check another code
      </a>
      
    </div>
    :
    <div className="results">
      <div className="results--patientcode">
        <h3 className="results--label">
          Booking No.:
        </h3>
        <h2 className="results--code">
          {state.data.booking[0].id}  
        </h2>
      </div>
      <div className="results--details">
        <h3 className="results--label">
          Patient Name:
        </h3>
        <h1 className="results--date">
          {/* Mmmm DD, YYYY, <br />
          HH:mm */}
          {state.data.customer.first_name + " " + state.data.customer.last_name}
        </h1>
      </div>
      <div className="results--details">
        <h3 className="results--label">
          Registered on:
        </h3>
        <h1 className="results--date">
          {/* Mmmm DD, YYYY, <br />
          HH:mm */}
          {state.data.booking[0].booking_time}
        </h1>
      </div>
      
      {/* <div className="results--details">
        <h3 className="results--label">
          Extracted on:
        </h3>
        <h1 className="results--date"> */}
          {/* Mmmm DD, YYYY, <br />
          HH:mm */}
          {/* {state.data.booking_details[0].extracted_on}   */}
        {/* </h1>
      </div> */}
      
      {/* <div className="results--details">
        <h3 className="results--label">
          Examined on:
        </h3>
        <h1 className="results--date">
          Mmmm DD, YYYY, <br />
          HH:mm
        </h1>
      </div> */}




      <div className="results--details">
        <h3 className="results--label bold">
          Releasing of Results:
        </h3>
        <h1 className="results--date darkerblue">
          {state.data.booking_package_details[bookID][0].updated_on === null?"Not yet available":state.data.booking_package_details[bookID][0].updated_on}  
        </h1>
      </div>
      <a href="/patient-portal"  >
                Check another code
      </a>
      
    </div>}
    </>
  )
}
import React from 'react'
import { Row, Col, Image, Space } from 'antd';
import avega from '../images/1_Avega.png'
import maxicare from '../images/maxicare.png'
import valueCare from '../images/valueCare.png'
import lacson from '../images/lacson.png'
import general from '../images/general.png'
import intellicare from '../images/intellicare.png'
import cocolife from '../images/cocolife.png'
import mediCard from '../images/mediCard.png'
import axa from '../images/axa.png'
import etiqa from '../images/etiqa.png'
import insular_life from '../images/insular_life.png'
import health_care from '../images/health_care.png'
import careHealth from '../images/careHealth.png'


const HmoPage = () => {

    return (
      <Row className='carousel-container px-5 pb-4'>
        <Col span={24}>
          <Row>
            <Col span={24} className='hmo-title mt-4'>
              Got an HMO Plan?
            </Col>
          </Row>
          <Row>
            <Col span={24} className='hmo-desc mb-4'>
              Maximize it here at QR Diagnostics
            </Col>
          </Row>
          <Row className='logo-container'>
            <Col span={24}>
              <Row className="responsive-space-container">
                <Space size={[16, 16]} wrap className="responsive-space center">
                  <Image
                    height='auto'
                    src={avega}
                    alt="img1"
                    preview={false}
                    className='p-4 logo-img'
                  />
               
                  <Image
                    height='auto'
                    src={maxicare}
                    alt="img1"
                    preview={false}
                    className='p-4 logo-img'
                  />
               
                  <Image
                    height='auto'
                    src={valueCare}
                    alt="img1"
                    preview={false}
                    className='p-4 logo-img'
                  />
                   <Image
                    height='auto'
                    src={lacson}
                    alt="img1"
                    preview={false}
                    className='p-4 logo-img'
                  />
                </Space>
              </Row>
              <Row>
                <Space size={[16, 16]} wrap className="responsive-space center">
                  <Image
                    height='auto'
                    src={general}
                    alt="img1"
                    preview={false}
                    className='logo-img'
                  />
                  <Image
                    height='auto'
                    src={intellicare}
                    alt="img1"
                    preview={false}
                    className='p-4 logo-img'
                  />
                  <Image
                    height='auto'
                    src={cocolife}
                    alt="img1"
                    preview={false}
                    className='logo-img'
                  />
                  <Image
                    height='auto'
                    src={careHealth}
                    alt="img1"
                    preview={false}
                    className='p-4 logo-img'
                  />
                </Space>
              </Row>
              <Row>
                <Space size={[16, 16]} wrap className="responsive-space center">
                  <Image
                    height='auto'
                    src={mediCard}
                    alt="img1"
                    preview={false}
                    className='p-4 logo-img'
                  />
                  <Image
                    height='auto'
                    src={axa}
                    alt="img1"
                    preview={false}
                    className='p-4 logo-img'
                  />
                  <Image
                    height='auto'
                    src={etiqa}
                    alt="img1"
                    preview={false}
                    className='p-4 logo-img'
                  />
                  <Image
                    height='auto'
                    src={insular_life}
                    alt="img1"
                    preview={false}
                    className='logo-img'
                  />
                  <Image
                    height='auto'
                    src={health_care}
                    alt="img1"
                    preview={false}
                    className='p-4 logo-img'
                  />
                </Space>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    )
}

export default HmoPage


import { React, useState } from "react"
import "./SearchBar.css"
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { cardiology, clinicalMicroscopy, others, hematology, histopathology, microbiology, ultrasound, xRay, chemElectrolytes, chemGlucoseTests, chemKidneyFunctionTests, chemLipidProfile, chemLiverFunctionTests,chemPancreaticTest, seroHepatitis, seroImmunology, seroThyroid, seroTumor, homeWithin2kms, homeOutside2kms, homeOutside, packagesEmploymentA, packagesEmploymentB, packagesEmploymentC,packagesEmploymentD, packagesAnnualA, packagesAnnualB, packagesAnnualC, obgyneUltrasound, vaccines, diagnostic, seroCoaguation, medicalCerts } from "../ServicesItems"
const allServices = cardiology.concat(clinicalMicroscopy, others, hematology, histopathology, microbiology, ultrasound, xRay, chemElectrolytes, chemGlucoseTests, chemKidneyFunctionTests, chemLipidProfile, chemLiverFunctionTests,chemPancreaticTest, seroHepatitis, seroImmunology, seroThyroid, seroTumor, homeWithin2kms, homeOutside2kms, homeOutside, packagesEmploymentA, packagesEmploymentB, packagesEmploymentC,packagesEmploymentD, packagesAnnualA, packagesAnnualB, obgyneUltrasound, vaccines, diagnostic, seroCoaguation, packagesAnnualC, medicalCerts)

export default function SearchBar(props){
  const [inputService, setInputService] = useState([]);
  const [serviceEntered, setServiceEntered] = useState("");

  const handleFilter = (event) => {
    const searchService = event.target.value;
    setServiceEntered(searchService);
    const newFilter = allServices.filter((value) => {
      return value.test_name.toLowerCase().includes(searchService.toLowerCase());
    });

    if (searchService === "") {
      setInputService([]);
    } else {
      setInputService(newFilter);
    }
  };

  const clearInput = () => {
    setInputService([]);
    setServiceEntered("");
  };

  return(
    <div className="search--bar">
      <div classname="search--inputs">
        <input
          type="text"
          placeholder={props.placeholder}
          value={serviceEntered}
          onChange={handleFilter}
        />
        <div className="search--button">
          {inputService.length === 0 ? (
            <SearchIcon />
          ) : (
            <CloseIcon id="clearBtn" onClick={clearInput} />
          )}
        </div>
      </div>
      {inputService.length !== 0 && (
        <div className="services--result">
          {inputService.slice(0,15).map((value, key) => {
            return(
              <a className="services--item" href={value.url}>
                <p>{value.test_name}</p>
              </a>
            ) 
          })}
        </div>
      )}
    </div>
  )
}






import React from "react";
import DoctorPhoto from "../images/doctorPhoto.png"
import DocSulit from "../images/doc-sulit.jpg"
import DocSaavedra from "../images/doc-saavedra.jpg"
import DocPazon from "../images/doc-pazon.jpg"
import DocSydiongco from "../images/doc-sydiongco.jpg"
import SearchBar from "../components/SearchBar"

export default function Doctors(){
  return(
    <div className="doctors">
      <div className="doctors--heading">
         <h1 className="title">Our Doctors</h1>
      </div>

      {/* <SearchBar
        id="doctors--search"
        placeholder="Search Doctor"
        name="doctors--search"
      /> */}
    
      {/* <div className="doctor--profile">
        <div className="doctor--content">
          <div className="doctor--title">
            <h1>Dr. Samanta Sim-Sulit, MD</h1>
            <h2>Pediatrician</h2>
          </div>
          <div className="doctor--experience">
            <h3>Experience</h3>
            <p>
              <span className="bold">Pediatric Residency Training</span> 
              <br />
              Chief Resident (Jan 1 2017 - Sept 30, 2017)
              <br />
              Our Lady of Lourdes Hospital Sta. Mesa Manila
            </p>
            <p>
              <span className="bold">Doctor of Medicine</span>
              <br />
              San Beda College Mendiola Manila
            </p>

          </div>

          <div className="doctor--honors">
            <h3>Honors/Awards/Achievements</h3>
            <p>
              <span className="bold">1st Place</span> 
              <br />
              Interdepartmental Case Report Contest: 
              <br />
              “The Bottom Line: A Case of Caudal Regression Syndrome: 2016”            
            </p>
            <p>
              <span className="bold">2nd Place</span>
              <br />
              First Interhospital Grandrounds Contest,
              <br />
              Caudal Regression Syndrome, 2016
            </p>
            <p>
              <span className="bold">Best in Research in Pharmacology</span>
              <br />
              Doctor of Medicine
            </p>
          </div>         
          <div className="doctor--sched">
            <h3>Available Schedule:</h3>
            <h2>MON & WED Morning</h2>
          </div>    
        </div>
      </div> */}

      {/* <div className="doctor--profile">
        <div className="doctor--info">
          <div className="doctor--title">
            <h1>Dr. Christine Trinity Yu- Saavedra, MD </h1>
            <h2>Diplomate in Internal Medicine </h2>
          </div>
          <div className="doctor--experience">
            <h3>Experience</h3>
            <p>
              <span className="bold">Diplomate in Internal Medicine </span> 
              <br />
              Remedios Trinidad Romualdez Hospital 
            </p>
            <p>
              <span className="bold">Post Graduation Internship </span>
              <br />
                Eastern Visayas Regional Medical Center            
            </p>
           <p>
              <span className="bold">Doctor of Medicine</span>
              <br />
                Remedios Trinidad Romualdez Medical Foundation            
            </p>
          </div>

          <div className="doctor--honors">
            <h3>Honors/Awards/Achievements</h3>
            <p>
              <span className="bold">First Place </span> 
              <br />
              Research Protocol Category: Incidence, Clinical Profile and In Hospital Outcomes of Patients with Cardiogenic Shock Complicating Acute Myocardial Infarction Admitted at a Tertiary Hospital
              <br />
              PCP Eastern Visayas Chapter 
              <br />
              February 8, 2019 
            </p>
            <p>
              <span className="bold">Alangalang Outstanding Achiever </span>
              <br />
              November 8, 2012
            </p>
            <p>
              <span className="bold">Most Outstanding Medical Intern</span>
              <br />
              Makati Medical Center 
              <br />
              April 10, 2010 
            </p>
            <p>
              <span className="bold">Fourth Honors</span>
              <br />
              Doctor of Medicine 
              <br />
              Remedios Trinidad Romualdez Medical Foundation 
              <br />
              April 10, 2010  
            </p>
          </div>         
          <div className="doctor--sched">
            <h3>Available Schedule:</h3>
            <h2>MON & WED Afternoon</h2>
          </div>    
        </div> 
      </div> */}

      {/* <div className="doctor--profile">
         <img src={DocPazon} alt=""/> 
        <div className="doctor--info">
          <div className="doctor--title">
            <h1>Almia A. Pazon, MD, DPBO-HNS </h1>
            <h2>Ears, Nose, Throat, Head and Neck Surgery </h2>
          </div>
          <div className="doctor--experience">
            <h3>Experience</h3>
            <p>
              <span className="bold">Diplomate of the Philippine Board of Otorhinolaryngology-Head and Neck Surgery </span> 
            </p>
            <p>
              <span className="bold">Resident (ENT) </span>
              <br />
              Quezon City General Hospital 
            </p>
            <p>
              <span className="bold">Doctor of Medicine </span>
              <br />
              Far Eastern University-Nicanor Reyes 
              <br />
              Medical Foundation 
            </p>
            <p>
              <span className="bold">Degree in Bachelor of Science in Nursing</span>
              <br />
              Trinity University of Asia-St. Luke's College of Nursing 
              <br />
              2010 
            </p>

          </div>

          <div className="doctor--honors">
            <h3>Honors/Awards/Achievements</h3>
            <p>
              <span className="bold">Top 4, Philippine Board of Otorhinolaryngology Head and Neck Surgery </span> 
              <br />
              October 2021 
            </p>
            <p>
              <span className="bold">Chief Resident (ENT)</span>
              <br />
              Quezon City General Hospital 
              <br />
              January 1, 2021 - August 30, 2021 
            </p>

          </div>         
          <div className="doctor--sched">
            <h3>Available Schedule:</h3>
            <h2>TUE & FRI Morning </h2>
          </div>    
        </div> 



      
      
      </div> */}

      <div className="doctor--profile">
        {/* <img src={DocSydiongco} alt=""/> */}
        <div className="doctor--info">
          <div className="doctor--title">
            <h1>Jeff Thomas B. Sydiongco Jr., MD, FPCP</h1>
            {/* <h2>Diplomate in Internal Medicine </h2> */}
          </div>
          <div className="doctor--experience">
          <h3>Educational Background</h3>
            {/* <h3>Experience</h3> */}
            <p>
              {/* <span className="bold">Diplomate in Internal Medicine</span> */}
              Residency Training in Internal Medicine 
              <br />
              Remedios Trinidad Romualdez Hospital 
            </p>
            <p>
              <span className="bold">Post Graduate Internship</span>
              <br />
              Department of Health 
              <br />
              Philippine Specialized Centers for Specialized Health Care (DOH-PCSHC) 
              <br />
              Quezon City 
            </p>
            <p>
              <span className="bold">Medicine </span>
              <br />
              Remedios T. Romualdez Medical Foundation 
              <br />
              College of Medicine, Tacloban City, Leyte  
              <br />
              Year Graduated: 2015  
            </p>

          </div>

          <div className="doctor--honors">
            <h3>Affiliations:</h3>
            <p>
            •	PHILIPPINE COLLEGE OF PHYSICIANS
              {/* <span className="bold">Oral Revalida (Highest)</span> 
              <br />
              Doctor of Medicine 
              <br />
              Remedios T. Romualdez Medical Foundation 
              <br />
              April 2015          
            </p>
            <p>
              <span className="bold">Award for Academic Excellence (Highest)</span>
              <br />
              Bachelor of Science in Nursing
              <br />
              Remedios T. Romualdez Medical Foundation 
              <br />
              April 2007 */}
            </p>
          </div>         
          <div className="doctor--sched">
          <h3>Available Schedule:</h3>
          <h2>MON & TUE </h2>
            {/* <h3>Available Schedule:</h3>
            <h2>TUE & THU Afternoon </h2> */}
          </div>    
        </div>
      </div>

      <div className="doctor--profile">
        {/* <img src={DocSydiongco} alt=""/> */}
        <div className="doctor--info">
          <div className="doctor--title">
            <h1>Dr. Wendel Jason Codilan, MD, FPCP</h1>
          </div>
          <div className="doctor--experience">
            <h3>Educational Background</h3>
            <p>
              Residency Training in Internal Medicine
              <br />
              Perpetual Succour Hospital of Cebu, Inc. 
            </p>
            <p>
              <span className="bold">Post Graduate Internship</span>
              <br />
              Remedios Trinindad Romualdez Hospital 
            </p>
            <p>
              <span className="bold">Medicine </span>
              <br />
              Remedios T. Romualdez Medical Foundation 
              <br />
              College of Medicine, Tacloban City, Leyte  
              <br />
              Year Graduated: 2015  
            </p>

          </div>

          <div className="doctor--honors">
            <h3>Affiliations:</h3>
            <p>
            •	PHILIPPINE COLLEGE OF PHYSICIANS
            <br />
            •	PHILIPPINE MEDICAL ASSOCIATION
            </p>
          </div>         
          <div className="doctor--sched">
          <h3>Available Schedule:</h3>
          <h2>WED & THU </h2>
          </div>    
        </div>
      </div>
      
      <div className="doctor--profile">
        {/* <img src={DocSydiongco} alt=""/> */}
        <div className="doctor--info">
          <div className="doctor--title">
            <h1>Dr. Diane Mae Malinao-Agravante, MD</h1>
            <h2>Diplomate in Internal Medicine </h2>
          </div>
          <div className="doctor--experience">
            <h3>Educational Background</h3>
            <p>
              <span className="bold">Diplomate in Internal Medicine </span> 
              <br /> 
              Residency Training in Internal Medicine
              <br />
              Remedios Trinidad Romualdez Hospital
            </p>
            <p>
              <span className="bold">Post Graduate Internship</span>
              <br />
              Davao Doctors Hospital 
              <br />
              Davao City
            </p>
            <p>
              <span className="bold">Medicine </span>
              <br />
              Silliman University
              <br />
              Dumaguete, Negros Oriental 
            </p>

          </div>

          <div className="doctor--honors">
            <h3>Affiliations:</h3>
            <p>
            •	PHILIPPINE COLLEGE OF PHYSICIANS
            <br />
            •	PHILIPPINE MEDICAL ASSOCIATION
            <br />
            •	LEYTE MEDICAL SOCIETY 
            </p>
          </div>         
          <div className="doctor--sched">
          <h3>Available Schedule:</h3>
          <h2>FRI & SAT </h2>
          </div>    
        </div>
      </div>

      <div className="doctor--profile">
        {/* <img src={DocSydiongco} alt=""/> */}
        <div className="doctor--info">
          <div className="doctor--title">
            <h1>Dr. Patrick E. Dyococo, MD.</h1>
            <h2>Doctor of Medicine </h2>
          </div>
          <div className="doctor--experience">
            <h3>Experience</h3>
            <p>
              <span className="bold">Residency in Training</span>
              <br /> 
              Department of Internal Medicine
              <br />
              Remedios Trinidad Romualdez Hospital 
            </p>
            <p>
              <span className="bold">Post Graduate Internship</span>
              <br />
              Remedios Trinidad Romualdez Hospital  
            </p>
            <p>
              <span className="bold">Medicine </span>
              <br />
              Dona Remedios Trinidad Romualdez Medical Foundation
              <br />
              Doctor of Medicine 
              <br />
              Year Graduated: 2017 
            </p>

          </div>

          <div className="doctor--honors">
            <h3>Professional Affliation</h3>
            <p>
              <span className="bold">Leyte Medical Society</span> 
              <br />
              Member 
              <br />
            </p>
            <p>
              <span className="bold">Philippine Medical Association</span>
              <br />
              Member
              <br />
            </p>
            <p>
              <span className="bold">Philippine College of Physicians</span>
              <br />
            Diplomate
              <br />
            </p>
          </div>         
          {/* <div className="doctor--sched">
            <h3>Available Schedule:</h3>
            <h2>TUE & THU Afternoon </h2>
          </div>     */}
        </div>



      
      
      </div>
    </div>

  )
}













//Doctors with navbar
// import React, { useState } from "react"
// import DoctorsData from "../DoctorsData"
// import DoctorPhoto from "../images/doctorPhoto.png"
// import SearchBar from "../components/SearchBar"
// export default function Doctors(){

//   const [toggleState, setToggleState] = useState(1)

//   const toggleTab = (index) =>{
//     setToggleState(index)
//   }

//   const doctorsElements = DoctorsData.map(
//     doctor => 
//       <div className={toggleState===doctor.specialty ? "content active-content" : "content"}>

//         <img 
//           src={DoctorPhoto}
//           alt="" 
//           className="content--photo" />
//         <div className="content--info">
//           <h1> {doctor.fullname}, {doctor.suffix} </h1>
//           <h2> {
//             (doctor.specialty===1) ? "Ears, Nose, Throat, Head and Neck Surgery" : 
//             (doctor.specialty===2) ?"Internal Medicine" :
//             (doctor.specialty===3) ? "Pediatrician" : ""
//           } </h2>
//           <h3> 
//             <i className="fa fa-phone content-symbol" />        
//             {doctor.contact} 
//           </h3>
//           <h3> 
//             <i className="fa fa-envelope content-symbol" />
//             {doctor.email} </h3>
//           <h4> 
//             <i className="fa fa-calendar content-symbol" />
//             {doctor.schedule} </h4>

//         </div>


//       </div>
//   )

//   return(
//     <div className="doctors">
//       <div className="doctors--heading">
//         <h1 className="title">Our Doctors</h1>
//         <SearchBar 
//           id = "doctors--search"
//           placeholder="Search Doctor"
//           name = "doctors--search"
        
//         />

//       </div>
//       <div className="doctors--list">
//         <div className="doctors--tab">
//           <h1 
//           onClick = {()=>toggleTab(1)}
//           className={toggleState===1?"tab active-tab": "tab"}>
//             Ears, nose, throat, Head and Neck Surgery 
//           </h1>
//           <h1 
//           onClick = {()=>toggleTab(2)}
//           className={toggleState===2?"tab active-tab": "tab"}>
//             Internal Medicine
            
//           </h1>
//           <h1 
//           onClick = {()=>toggleTab(3)}
//           className={toggleState===3?"tab active-tab": "tab"}>
//             Pediatrician
//           </h1>

//         </div>
//         <div className="doctors--content">
//           {doctorsElements}

//         </div>

//       </div>
//     </div>
//   )
// }
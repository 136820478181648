import React from "react"
import BookNowBtn from "./BookNowBtn"
import Map from "../images/map.jpg"

export default function Location(){
  return(
    <div className="location">
      <div className="location--content">
        <h1>Location & Hours</h1>
        <p>Unit A, M Block Building, Barangay 77, Marasbaras 6500 Tacloban City, Philippines</p>
        <p>Monday - Saturday, 6am - 5pm</p>
        {/* <BookNowBtn /> */}
      </div>
      <div className="location--picture">
        <img src={Map} />
      </div>
    </div>
  )
}
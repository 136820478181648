import React from "react"
import Hero from "../components/Hero"
import HomeServices from "../components/HomeServices"
import AboutUs from "../components/AboutUs"
import Location from "../components/Location"
import BookNowBtn from "../components/BookNowBtn"


export default function Home(){
  return(
    <div className="home">
      <Hero />
      <HomeServices />
      <AboutUs />
      <Location />
      
    </div>
  )
}
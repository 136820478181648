import React, {useState} from "react"
import { Link, useParams } from "react-router-dom"

import SearchBar from "../components/SearchBar"
import ServicesList from "../ServicesList"
import { cardiology, clinicalMicroscopy, others, hematology, histopathology, microbiology, ultrasound, xRay, chemElectrolytes, chemGlucoseTests, chemKidneyFunctionTests, chemLipidProfile, chemLiverFunctionTests,chemPancreaticTest, seroHepatitis, seroImmunology, seroThyroid, seroTumor, homeWithin2kms, homeOutside2kms, homeOutside, packagesEmploymentA, packagesEmploymentB, packagesEmploymentC,packagesEmploymentD, packagesEmploymentE, packagesEmploymentF, packagesAnnualA, packagesAnnualB, packagesAnnualC, packagesAnnualE, packagesAnnualD, medicalCerts, seroCoaguation, obgyneUltrasound, vaccines, diagnostic } from "../ServicesItems"
// import SoonList from "../SoonList"

export default function Services(){
  const { id } = useParams()

  const [selectedTab, setSelectedTab] = useState( id )
  const handleClick = (index) => {
    setSelectedTab(index)
  }


  const servicesTabElements = ServicesList.map(
    service => 
      <Link 
        to={service.url}
        className={id===service.url ?"tab active-tab ":"tab"}
        onClick={()=>handleClick("ultrasound")}
      >
        {service.service_name}
      </Link>
  )
  // const soonTabElements = SoonList.map(
  //   service => 
  //     <Link 
  //       to={service.url}
  //       className={id===service.url ?"tab active-tab yellow":"tab yellow "}
  //       onClick={()=>handleClick("ultrasound")}
  //     >
  //       {service.service_name}
  //     </Link>
  // )
  

  return (
    <div className="servicespage">
      <div className="servicespage--heading">
        <h1 className="title">Our Services </h1>
        <SearchBar 
          id = "services--search"
          placeholder="Search Services"
          name = "services--search"
        />
        <br/>
        <br/>
        <br/>
        <p className="servicespage-disclaimer" >Disclaimer: Prices may change.</p>
      </div>
      
     
      <div className="servicespage--list">
        <div className="servicespage--tab">
          {servicesTabElements}
        </div>
      

        <div className="servicespage--content">
          <div className={id==="clinical-microscopy"?"services--content active-service":"services--content"}>
            <h1> Clinical Microscopy </h1>
            <table className="table">
              <tbody>
                  {
                    clinicalMicroscopy.map(x=>
                      <tr>
                      <td className="col-9">{x.test_name}{x.test_info !== "" && <span className="info"><br />{x.test_info}</span>}</td>
                      <td className="col-3">₱ {x.test_price}</td>
                      </tr>
                      )
                  }
              </tbody>
            </table>
          </div>

          <div className={id==="hematology"?"services--content active-service":"services--content"}>
            <h1> Hematology </h1>
            <table className="table">
              <tbody>
                  {
                    hematology.map(x=>
                      <tr>
                      <td className="col-9">{x.test_name}{x.test_info !== "" && <span className="info"><br />{x.test_info}</span>}</td>
                      <td className="col-3">₱ {x.test_price}</td>
                      </tr>
                      )
                  }
              </tbody>
            </table>
          </div>    

          {/* Chemistry */}
          <div className={id==="chemistry"?"services--content active-service":"services--content"}>
            <h1> Electrolytes </h1>
            <table className="table">
              <tbody>
                  {
                    chemElectrolytes.map(x=>
                      <tr>
                      <td className="col-9">{x.test_name}{x.test_info !== "" && <span className="info"><br />{x.test_info}</span>}</td>
                      <td className="col-3">₱ {x.test_price}</td>
                      </tr>
                      )
                  }
              </tbody>
            </table>
          </div> 
          <div className={id==="chemistry"?"services--content active-service":"services--content"}>
            <h1> Glucose Tests </h1>
            <table className="table">
              <tbody>
                  {
                    chemGlucoseTests.map(x=>
                      <tr>
                      <td className="col-9">{x.test_name}{x.test_info !== "" && <span className="info"><br />{x.test_info}</span>}</td>
                      <td className="col-3">₱ {x.test_price}</td>
                      </tr>
                      )
                  }
              </tbody>
            </table>
          </div> 
          <div className={id==="chemistry"?"services--content active-service":"services--content"}>
            <h1> Kidney Function Tests </h1>
            <table className="table">
              <tbody>
                  {
                    chemKidneyFunctionTests.map(x=>
                      <tr>
                      <td className="col-9">{x.test_name}{x.test_info !== "" && <span className="info"><br />{x.test_info}</span>}</td>
                      <td className="col-3">₱ {x.test_price}</td>
                      </tr>
                      )
                  }
              </tbody>
            </table>
          </div> 
          <div className={id==="chemistry"?"services--content active-service":"services--content"}>
            <h1> Lipid Profile </h1>
            <table className="table">
              <tbody>
                  {
                    chemLipidProfile.map(x=>
                      <tr>
                      <td className="col-9">{x.test_name}{x.test_info !== "" && <span className="info"><br />{x.test_info}</span>}</td>
                      <td className="col-3">₱ {x.test_price}</td>
                      </tr>
                      )
                  }
              </tbody>
            </table>
          </div> 
          <div className={id==="chemistry"?"services--content active-service":"services--content"}>
            <h1> Pancreatic Test </h1>
            <table className="table">
              <tbody>
                  {
                    chemPancreaticTest.map(x=>
                      <tr>
                      <td className="col-9">{x.test_name}{x.test_info !== "" && <span className="info"><br />{x.test_info}</span>}</td>
                      <td className="col-3">₱ {x.test_price}</td>
                      </tr>
                      )
                  }
              </tbody>
            </table>
          </div> 
          <div className={id==="chemistry"?"services--content active-service":"services--content"}>
            <h1> Liver Function Tests </h1>
            <table className="table">
              <tbody>
                  {
                    chemLiverFunctionTests.map(x=>
                      <tr>
                      <td className="col-9">{x.test_name}{x.test_info !== "" && <span className="info"><br />{x.test_info}</span>}</td>
                      <td className="col-3">₱ {x.test_price}</td>
                      </tr>
                      )
                  }
              </tbody>
            </table>
          </div> 

          {/* Serology */}
          <div className={id==="serology"?"services--content active-service":"services--content"}>
            <h1> Immunology </h1>
            <table className="table">
              <tbody>
                  {
                    seroImmunology.map(x=>
                      <tr>
                      <td className="col-9">{x.test_name}{x.test_info !== "" && <span className="info"><br />{x.test_info}</span>}</td>
                      <td className="col-3">₱ {x.test_price}</td>
                      </tr>
                      )
                  }
              </tbody>
            </table>
          </div>          

          <div className={id==="serology"?"services--content active-service":"services--content"}>
            <h1> Hepatitis Profile Screening </h1>
            <table className="table">
              <tbody>
                  {
                    seroHepatitis.map(x=>
                      <tr>
                      <td className="col-9">{x.test_name}{x.test_info !== "" && <span className="info"><br />{x.test_info}</span>}</td>
                      <td className="col-3">₱ {x.test_price}</td>
                      </tr>
                      )
                  }
              </tbody>
            </table>
          </div>  
          <div className={id==="serology"?"services--content active-service":"services--content"}>
            <h1> Thyroid Profile </h1>
            <table className="table">
              <tbody>
                  {
                    seroThyroid.map(x=>
                      <tr>
                      <td className="col-9">{x.test_name}{x.test_info !== "" && <span className="info"><br />{x.test_info}</span>}</td>
                      <td className="col-3">₱ {x.test_price}</td>
                      </tr>
                      )
                  }
              </tbody>
            </table>
          </div>   
          <div className={id==="serology"?"services--content active-service":"services--content"}>
            <h1> Couguation Studies </h1>
            <table className="table">
              <tbody>
                  {
                    seroCoaguation.map(x=>
                      <tr>
                      <td className="col-9">{x.test_name}{x.test_info !== "" && <span className="info"><br />{x.test_info}</span>}</td>
                      <td className="col-3">₱ {x.test_price}</td>
                      </tr>
                      )
                  }
              </tbody>
            </table>
          </div>  
          <div className={id==="serology"?"services--content active-service":"services--content"}>
            <h1> Tumor Markers </h1>
            <table className="table">
              <tbody>
                  {
                    seroTumor.map(x=>
                      <tr>
                      <td className="col-9">{x.test_name}{x.test_info !== "" && <span className="info"><br />{x.test_info}</span>}</td>
                      <td className="col-3">₱ {x.test_price}</td>
                      </tr>
                      )
                  }
              </tbody>
            </table>
          </div>             

          {/* Histopathology */}
          <div className={id==="histopathology"?"services--content active-service":"services--content"}>
            <h1> Histopathology </h1>
            <table className="table">
              <tbody>
                  {
                    histopathology.map(x=>
                      <tr>
                      <td className="col-9">{x.test_name}{x.test_info !== "" && <span className="info"><br />{x.test_info}</span>}</td>
                      <td className="col-3">₱ {x.test_price}</td>
                      </tr>
                      )
                  }
              </tbody>
            </table>
          </div> 

          {/* Microbiology */}
          <div className={id==="microbiology"?"services--content active-service":"services--content"}>
            <h1> Microbiology </h1>
            <table className="table">
              <tbody>
                  {
                    microbiology.map(x=>
                      <tr>
                      <td className="col-9">{x.test_name}{x.test_info !== "" && <span className="info"><br />{x.test_info}</span>}</td>
                      <td className="col-3">₱ {x.test_price}</td>
                      </tr>
                      )
                  }
              </tbody>
            </table>
          </div> 

        {/* Covid tests */}
          <div className={id==="others"?"services--content active-service":"services--content"}>
            <h1> Other Tests </h1>
            <table className="table">
              <tbody>
                  {
                    others.map(x=>
                      <tr>
                      <td className="col-9">{x.test_name}{x.test_info !== "" && <span className="info"><br />{x.test_info}</span>}</td>
                      <td className="col-3">₱ {x.test_price}</td>
                      </tr>
                      )
                  }
              </tbody>
            </table>
          </div> 

          {/* xray */}
          <div className={id==="x-ray"?"services--content active-service":"services--content"}>
            <h1> X-Ray </h1>
            <table className="table">
              <tbody>
                  {
                    xRay.map(x=>
                      <tr>
                      <td className="col-9">{x.test_name}{x.test_info !== "" && <span className="info"><br />{x.test_info}</span>}</td>
                      <td className="col-3">₱ {x.test_price}</td>
                      </tr>
                      )
                  }
              </tbody>
            </table>
          </div> 

          {/* cardiology */}
          <div className={id==="cardiology"?"services--content active-service":"services--content"}>
            <h1> Cardiology </h1>
            <table className="table">
              <tbody>
                  {
                    cardiology.map(x=>
                      <>
                      <tr>
                      <td className="col-9">{x.test_name}{x.test_info !== "" && <span className="info"><br />{x.test_info}</span>}</td>
                      <td className="col-3">₱ {x.test_price}</td>
                      </tr>
                      
                      </>
                      )
                  }
              </tbody>
            </table>
          </div> 

          {/* cardiology */}
          <div className={id==="ultrasound"?"services--content active-service":"services--content"}>
            <h1> Ultrasound</h1>
            <table className="table">
              <tbody>
                  {
                    ultrasound.map(x=>
                      <>
                      <tr>
                      <td className="col-9">{x.test_name}{x.test_info !== "" && <span className="info"><br />{x.test_info}</span>}</td>
                      {x.test_price !== "" && <td className="col-3">₱ {x.test_price}</td>}
                      </tr>
                      
                      </>
                      )
                  }
              </tbody>
            </table>
          </div> 
          <div className={id==="ultrasound"?"services--content active-service":"services--content"}>
            <h1>OBGYNE Ultrasound</h1>
            <table className="table">
              <tbody>
                  {
                    obgyneUltrasound.map(x=>
                      <>
                      <tr>
                      <td className="col-9">{x.test_name}{x.test_info !== "" && <span className="info"><br />{x.test_info}</span>}</td>
                      {x.test_price !== "" && <td className="col-3">₱ {x.test_price}</td>}
                      </tr>
                      
                      </>
                      )
                  }
              </tbody>
            </table>
          </div> 
          <div className={id==="vaccines"?"services--content active-service":"services--content"}>
            <h1>Vaccines</h1>
            <table className="table">
              <tbody>
                  {
                    vaccines.map(x=>
                      <>
                      <tr>
                      <td className="col-9">{x.test_name}{x.test_info !== "" && <span className="info"><br />{x.test_info}</span>}</td>
                      {x.test_price !== "" && <td className="col-3">₱ {x.test_price}</td>}
                      </tr>
                      
                      </>
                      )
                  }
              </tbody>
            </table>
          </div> 
          <div className={id==="diagnostic"?"services--content active-service":"services--content"}>
            <h1>Diagnostic Services</h1>
            <table className="table">
              <tbody>
                  {
                    diagnostic.map(x=>
                      <>
                      <tr>
                      <td className="col-9">{x.test_name}{x.test_info !== "" && <span className="info"><br />{x.test_info}</span>}</td>
                      {x.test_price !== "" && <td className="col-3">₱ {x.test_price}</td>}
                      </tr>
                      
                      </>
                      )
                  }
              </tbody>
            </table>
          </div> 
          
          {/* Home Service */}
          <div className={id==="home-service"?"services--content active-service":"services--content"}>
            <h1> Within 2 kms </h1>
            <table className="table">
              <tbody>
                  {
                    homeWithin2kms.map(x=>
                      <>
                      <tr>
                      <td className="col-9">{x.test_name}{x.test_info !== "" && <span className="info"><br />{x.test_info}</span>}</td>
                      <td className="col-3">₱ {x.test_price}</td>
                      </tr>
                      
                      </>
                      )
                  }
              </tbody>
            </table>
          </div> 

          <div className={id==="home-service"?"services--content active-service":"services--content"}>
            <h1> Above 2 kms </h1>
            <table className="table">
              <tbody>
                  {
                    homeOutside2kms.map(x=>
                      <>
                      <tr>
                      <td className="col-9">{x.test_name}{x.test_info !== "" && <span className="info"><br />{x.test_info}</span>}</td>
                      <td className="col-3">₱ {x.test_price}</td>
                      </tr>
                      
                      </>
                      )
                  }
              </tbody>
            </table>
          </div>

            <div className={id==="home-service"?"services--content active-service":"services--content"}>
            <h1> Outside Tacloban or Palo </h1>
            <table className="table">
              <tbody>
                  {
                    homeOutside.map(x=>
                      <>
                      <tr>
                      <td className="col-9">{x.test_name}{x.test_info !== "" && <span className="info"><br />{x.test_info}</span>}</td>
                      <td className="col-3">₱ {x.test_price}</td>
                      </tr>
                      
                      </>
                      )
                  }
              </tbody>
            </table>
          </div>

          {/* Packages */}
            <div className={id==="packages"?"services--content active-service":"services--content"}>
            <h1> Pre-Employment Package </h1>
            <table className="table">
              <thead>Employment A</thead>
              <tbody>
                  {
                    packagesEmploymentA.map(x=>
                      <>
                      <tr>
                      <td className="col-9">{x.test_name}{x.test_info !== "" && <span className="info"><br />{x.test_info}</span>}</td>
                      <td className="col-3">₱ {x.test_price}</td>
                      </tr>
                      </>
                      )
                  }
              </tbody>
            </table>
            <table className="table">
              <thead>Employment B</thead>
              <tbody>
                  {
                    packagesEmploymentB.map(x=>
                      <>
                      <tr>
                      <td className="col-9">{x.test_name}{x.test_info !== "" && <span className="info"><br />{x.test_info}</span>}</td>
                      <td className="col-3">₱ {x.test_price}</td>
                      </tr>
                      </>
                      )
                  }
              </tbody>
            </table>
            <table className="table">
              <thead>Standard Package</thead>
              <tbody>
                  {
                    packagesEmploymentC.map(x=>
                      <>
                      <tr>
                      <td className="col-9">{x.test_name}{x.test_info !== "" && <span className="info"><br />{x.test_info}</span>}</td>
                      <td className="col-3">₱ {x.test_price}</td>
                      </tr>
                      </>
                      )
                  }
              </tbody>
            </table>
            <table className="table">
              <thead>Uniformed Personnel</thead>
              <tbody>
                  {
                    packagesEmploymentD.map(x=>
                      <>
                      <tr>
                      <td className="col-9">{x.test_name}{x.test_info !== "" && <span className="info"><br />{x.test_info}</span>}</td>
                      <td className="col-3">₱ {x.test_price}</td>
                      </tr>
                      </>
                      )
                  }
              </tbody>
            </table>
            <table className="table">
              <thead>Kasambahay/Driver</thead>
              <tbody>
                  {
                    packagesEmploymentE.map(x=>
                      <>
                      <tr>
                      <td className="col-9">{x.test_name}{x.test_info !== "" && <span className="info"><br />{x.test_info}</span>}</td>
                      <td className="col-3">₱ {x.test_price}</td>
                      </tr>
                      </>
                      )
                  }
              </tbody>
            </table>
            <table className="table">
              <thead>Comprehensive</thead>
              <tbody>
                  {
                    packagesEmploymentF.map(x=>
                      <>
                      <tr>
                      <td className="col-9">{x.test_name}{x.test_info !== "" && <span className="info"><br />{x.test_info}</span>}</td>
                      <td className="col-3">₱ {x.test_price}</td>
                      </tr>
                      </>
                      )
                  }
              </tbody>
            </table>
            {/* <p>Medical Certificate available upon request</p> */}
          </div>

          <div className={id==="packages"?"services--content active-service":"services--content"}>
            <h1> Annual Wellness Package </h1>
            <table className="table">
              <thead>Basic Package</thead>
              <tbody>
                  {
                    packagesAnnualA.map(x=>
                      <>
                      <tr>
                      <td className="col-9">{x.test_name}{x.test_info !== "" && <span className="info"><br />{x.test_info}</span>}</td>
                      <td className="col-3">₱ {x.test_price}</td>
                      </tr>
                      </>
                      )
                  }
              </tbody>
            </table>
            <table className="table">
              <thead>Standard Package</thead>
              <tbody>
                  {
                    packagesAnnualB.map(x=>
                      <>
                      <tr>
                      <td className="col-9">{x.test_name}{x.test_info !== "" && <span className="info"><br />{x.test_info}</span>}</td>
                      <td className="col-3">₱ {x.test_price}</td>
                      </tr>
                      </>
                      )
                  }
              </tbody>
            </table>
            <table className="table">
              <thead>Premium Package</thead>
              <tbody>
                  {
                    packagesAnnualC.map(x=>
                      <>
                      <tr>
                      <td className="col-9">{x.test_name}{x.test_info !== "" && <span className="info"><br />{x.test_info}</span>}</td>
                      <td className="col-3">₱ {x.test_price}</td>
                      </tr>
                      </>
                      )
                  }
              </tbody>
            </table>
            <table className="table">
              <thead>Gold Package</thead>
              <tbody>
                  {
                    packagesAnnualD.map(x=>
                      <>
                      <tr>
                      <td className="col-9">{x.test_name}{x.test_info !== "" && <span className="info"><br />{x.test_info}</span>}</td>
                      <td className="col-3">₱ {x.test_price}</td>
                      </tr>
                      </>
                      )
                  }
              </tbody>
            </table>
            <table className="table">
              <thead>Special Package Diabetic Panel</thead>
              <tbody>
                  {
                    packagesAnnualE.map(x=>
                      <>
                      <tr>
                      <td className="col-9">{x.test_name}{x.test_info !== "" && <span className="info"><br />{x.test_info}</span>}</td>
                      <td className="col-3">₱ {x.test_price}</td>
                      </tr>
                      </>
                      )
                  }
              </tbody>
            </table>

          </div>

          {/* Medical Certificate */}

          <div className={id==="medical-certificate"?"services--content active-service":"services--content"}>
            <h1> Medical Certificate </h1>
            <table className="table">
              <tbody>
                  {
                    medicalCerts.map(x=>
                      <>
                      <tr>
                      <td className="col-9">{x.test_name}{x.test_info !== "" && <span className="info"><br />{x.test_info}</span>}</td>
                      <td className="col-3">₱ {x.test_price}</td>
                      </tr>
                      
                      </>
                      )
                  }
              </tbody>
            </table>
          </div>



        </div>

        
      </div>

      <p className="homeservice">For Home Service Fee - Kindly ask our friendly staff for rates</p>

    </div>

  )
}
 

// const servicesClinicalMicroscopy = clinicalMicroscopy.map(service => 
//       <div className="services--content active-service">
//         <h1> {service.test_name} </h1>
//         <p> {service.test_info} </p>
//         <h3> Price: ₱ {service.test_price} </h3>
//       </div>)

//   const servicesHematology = hematology.map(
//     service => 
//       <div className="services--content active-service">
//         <h1> {service.test_name} </h1>
//         <p> {service.test_info} </p>
//         <h3> Price: ₱ {service.test_price} </h3>
//       </div>
//   )
//   const servicesHistopathology = histopathology.map(
//     service => 
//       <div className="services--content active-service">
//         <h1> {service.test_name} </h1>
//         <p> {service.test_info} </p>
//         <h3> Price: ₱ {service.test_price} </h3>
//       </div>
//   )
//   const servicesMicrobiology = microbiology.map(
//     service => 
//       <div className="services--content active-service">
//         <h1> {service.test_name} </h1>
//         <p> {service.test_info} </p>
//         <h3> Price: ₱ {service.test_price} </h3>
//       </div>
//   )
//   const servicesCOVIDRapidTest = covidRapidTests.map(
//     service => 
//       <div className="services--content active-service">
//         <h1> {service.test_name} </h1>
//         <p> {service.test_info} </p>
//         <h3> Price: ₱ {service.test_price} </h3>
//       </div>
//   )

//   const servicesXRay = xRay.map(
//     service => 
//       <div className="services--content active-service">
//         <h1> {service.test_name} </h1>
//         <p> {service.test_info} </p>
//         <h3> Price: ₱ {service.test_price} </h3>
//       </div>
//   )
//   const servicesCardiology = cardiology.map(
//     service => 
//       <div className="services--content active-service">
//         <h1> {service.test_name} </h1>
//         <p> {service.test_info} </p>
//         <h3> Price: ₱ {service.test_price} </h3>
//       </div>
//   )
        //  {(id==="clinical-microscopy")? servicesClinicalMicroscopy:
        //     (id==="hematology")? servicesHematology :
        //     (id==="histopathology")? servicesHistopathology :
        //     (id==="microbiology")? servicesMicrobiology :
        //     (id==="covid-rapid-tests")? servicesCOVIDRapidTest :
        //     (id==="x-ray")? servicesXRay :
        //     (id==="cardiology")? servicesCardiology : null
        //   }
import React from "react"
import GoToTop from "../components/GoToTop"

export default function BookNow(){

  return(
    <div className="booknow">
      <GoToTop />
    </div>
  )
}
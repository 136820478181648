// import React, {useState} from "react"
import { useNavigate } from "react-router-dom"
import "../components/CommonCss.css"
import React, {useEffect, useState} from "react"
import { useParams } from "react-router-dom";
import axios from 'axios';
import Results from "./Results";
import toast, {Toaster} from "react-hot-toast";
import {toastStyle} from "../components/Toaster"

export const refreshPage = () => {
  window.location.reload();
}

export default function PatientPortal(){

  const navigate = useNavigate()
  const [patientCode, setPatientCode] = useState("")
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isLoading, setIsLoading] = useState(false)
  const [trackPatient, setTrackPatient] = useState({})

  const checkStatus = (e) =>{
    e.preventDefault()
    const code ={patientCode, lastName, firstName}
    // navigate("/results")
    setIsLoading(!isLoading)
    setTrackPatient(code)
  }

  const {id} = useParams();
  const [data, setData] = useState([]);
  const [bookingId, setBookingId] = useState(id);
  
  async function GetResults(){
      await axios({
      method: 'get',
      url: window.$link + 'bookings/track',
      withCredentials: false, 
      params: {
          api_key: window.$api_key,
          booking_id: patientCode,
          last_name: lastName,
          first_name: firstName,
      }
  }).then(function (response) {
    var data = response.data.data
    RedirectToResult(data)
  }).catch(function (error) {
    toast.error("CUSTOMER NOT FOUND!", {style: toastStyle()});
    setTimeout(() => {
      refreshPage()
    },1500);
  });
  }

  const RedirectToResult = (data) => {
    return navigate("/results", {state:{data}})
  }

  return(
    <div className="patientportal">
      <Toaster position="top-right"/>
      <h1>Patient's <br /> Portal</h1>
      <form onSubmit={checkStatus}> 
        <label >
          Enter Booking Number: <span className="red">*</span>
        </label >
        <input 
          className="text-box"
          type="text"
          id="patient-code"
          name="patient-code"
          required
          value={patientCode}
          onChange={(e)=>setPatientCode(e.target.value)}

        />
        <label className="mt-3">
          Enter Last Name: <span className="red">*</span>
        </label>
        <input 
          className="text-box"
          type="text"
          id="patient-code"
          name="patient-code"
          required
          value={lastName}
          onChange={(e)=>setLastName(e.target.value)}

        />
        <label className="mt-3">
          Enter First Name: <span className="red">*</span>
        </label>
        <input 
          className="text-box"
          type="text"
          id="patient-code"
          name="patient-code"
          required
          value={firstName}
          onChange={(e)=>setFirstName(e.target.value)}

        />
      
        {!isLoading?<button className="mt-4" onClick={GetResults}>Check Status</button>:<button disabled>Loading...</button>}
      
      </form>
      {/* <a href="" target="_blank" >
        Forgot patient code?
      </a> */}
    </div>
  )
  
}